// extracted by mini-css-extract-plugin
export var container = "styles-module--container--7d8ee";
export var content = "styles-module--content--0fff9";
export var desktop = "1340px";
export var giant = "2200px";
export var gridItem = "styles-module--gridItem--74120";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var oneItem = "styles-module--oneItem--7e54a";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var threeItems = "styles-module--threeItems--dc3a7";
export var twoItems = "styles-module--twoItems--7db9f";