import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { ITwoColumnText } from 'lam-toolkit-figma-plugin';
import LayoutProvider from '../../Common/LayoutProvider';
import TextAnimation from '../../Common/TextAnimation';

export const TwoColumnText = ({
  body,
  heading,
  secondaryHeading,
  subheading
}: ITwoColumnText) => {
  return (
    <LayoutProvider grid className={styles.container}>
      <div className={styles.leftCol}>
        <h2 className={cn(heading.typestyle, styles.heading)}>
          {heading.content}
        </h2>

        {secondaryHeading.content && (
          <h3
            className={cn(secondaryHeading.typestyle, styles.secondaryHeading)}
          >
            {secondaryHeading.content}
          </h3>
        )}
      </div>

      <div className={styles.rightCol}>
        {subheading.content && (
          <>
            {subheading.typestyle === 'h2' ? (
              <TextAnimation
                text={subheading.content}
                element="h3"
                className="h2"
              />
            ) : (
              <h3 className={cn(subheading.typestyle, styles.subheading)}>
                {subheading.content}
              </h3>
            )}
          </>
        )}

        <p className={cn(body.typestyle, styles.body)}>{body.content}</p>
      </div>
    </LayoutProvider>
  );
};
