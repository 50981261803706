// This is largely the same as ImageWithCaption
// I created new component to avoid breaking changes,
// but we should consider replacing ImageWithCaption with this if we have time at some point (lol)

import React from 'react';
import cn from 'classnames';
import { Button, SVG, TSVG, Video } from '@lam-agency/toolkit/components';
import {
  useCopyToClipboard,
  useFigmaImageSrc
} from '@lam-agency/toolkit/hooks';
import * as styles from './styles.module.scss';
import { IMediaItem } from 'lam-toolkit-figma-plugin';

interface IProps extends IMediaItem {
  className?: string;
  aspectRatio?: 'square' | 'landscape' | 'landscapeShorter';
  noBorder?: boolean;
}

export const MediaItem = ({
  image,
  showButtons,
  video,
  caption,
  className,
  aspectRatio = 'square',
  noBorder
}: IProps) => {
  const { copyImageToClipboard } = useCopyToClipboard();

  const imageSrc = useFigmaImageSrc(image);

  const noCrop = imageSrc.endsWith('.png');

  const handleCopyImageAsPNG = () => {
    copyImageToClipboard(imageSrc, 'Image copied to clipboard');
  };

  // todo - better semantic formatting so figure wraps media and caption

  const getCaptionIconColor = () => {
    switch (caption?.icon) {
      case 'check':
        return 'green';
      case 'x':
        return 'red';
      default:
        return '';
    }
  };
  const getCaptionIcon: () => TSVG | null = () => {
    switch (caption?.icon) {
      case 'check':
        return 'check';
      case 'x':
        return 'cross';
      default:
        return null;
    }
  };
  const captionIcon = getCaptionIcon();

  return (
    <div
      className={cn(styles.container, className, {
        [styles.noCrop]: noCrop
      })}
    >
      {image && (
        <figure
          className={cn(styles.imageContainer, styles[aspectRatio], {
            [styles.noBorder]: noBorder
          })}
        >
          <img className={cn(styles.image, {[styles.bounce]: !showButtons})} src={imageSrc} />

          {showButtons && (
            <div className={styles.buttons}>
              <Button className={styles.button} onClick={handleCopyImageAsPNG}>
                <SVG className={styles.buttonIcon} svg="copy" />
              </Button>
              <Button className={styles.button} to={`${imageSrc}?download=`}>
                <SVG className={styles.buttonIcon} svg="download" />
              </Button>
            </div>
          )}
        </figure>
      )}

      {video && <Video src={video.source} hasSound={video.hasSound} />}

      {caption && (
        <div className={cn('caption', styles.caption)}>
          {captionIcon && (
            <figure
              className={styles.captionIcon}
              style={{ color: getCaptionIconColor() }}
            >
              <SVG svg={captionIcon} />
            </figure>
          )}

          <p>{caption.text}</p>
        </div>
      )}
    </div>
  );
};
