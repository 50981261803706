// extracted by mini-css-extract-plugin
export var borderRadius = "styles-module--borderRadius--f078e";
export var bounce = "styles-module--bounce--f0109";
export var button = "styles-module--button--01034";
export var buttonIcon = "styles-module--buttonIcon--54f5e";
export var buttons = "styles-module--buttons--fe759";
export var caption = "styles-module--caption--9e1b0";
export var captionIcon = "styles-module--captionIcon--f3123";
export var container = "styles-module--container--0a5bf";
export var image = "styles-module--image--0ce49";
export var imageContainer = "styles-module--imageContainer--aceba";
export var landscape = "styles-module--landscape--c7a39";
export var landscapeShorter = "styles-module--landscapeShorter--cec0d";
export var noBorder = "styles-module--noBorder--08ad0";
export var noCrop = "styles-module--noCrop--a2763";
export var square = "styles-module--square--ed56a";