// extracted by mini-css-extract-plugin
export var buttons = "styles-module--buttons--99327";
export var card = "styles-module--card--2a10c";
export var container = "styles-module--container--a6f2c";
export var dark = "styles-module--dark--1ed7c";
export var desktop = "1340px";
export var giant = "2200px";
export var image = "styles-module--image--b6587";
export var imageContainer = "styles-module--imageContainer--ede82";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var light = "styles-module--light--10e10";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";