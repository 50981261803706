import React from 'react';
import {
  ColourInspector,
  LayoutProvider,
  SliceHeader
} from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { ISliceStyleInspector } from 'lam-toolkit-figma-plugin';
import LogoInspector from './components/LogoInspector';

const FigmaStyleInspector = ({
  colorHeader,
  colorRows,
  logoHeader,
  logos
}: ISliceStyleInspector) => {
  return (
    <LayoutProvider grid paddingX paddingY>
      <div className={styles.logos}>
        <SliceHeader className={styles.sliceHeader} {...logoHeader} />

        <LogoInspector logos={logos} />
      </div>

      <div className={styles.colours}>
        <SliceHeader
          className={styles.sliceHeader}
          alignButtons="left"
          {...colorHeader}
        />
        <ColourInspector colorRows={colorRows} />
      </div>
    </LayoutProvider>
  );
};

export default FigmaStyleInspector;
