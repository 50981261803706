import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import {
  ImageCopyDownloadButtons,
  IntersectionAnimation,
} from '@lam-agency/toolkit/components';

interface IProps {
  logos?: string[];
}

const LogoInspector = ({ logos }: IProps) => {
  return (
    <div className={styles.container}>
      <LogoPreview />
    </div>
  );
};

export default LogoInspector;

const LogoPreview = () => {
  return (
    <>
      <LogoTile logoSrc="images/logo-light.svg" scheme="dark" />
      <LogoTile logoSrc="images/logo-dark.svg" scheme="light" />
    </>
  );
};

const LogoTile = ({
  logoSrc,
  scheme
}: {
  logoSrc?: string;
  scheme: 'light' | 'dark';
}) => {
  console.log('logoSrc', logoSrc);
  
  return (
    <IntersectionAnimation
      animation="fadeGrow"
      className={cn(styles.card, styles[scheme])}
    >
      <ImageCopyDownloadButtons
        colorScheme={scheme}
        logoDataUrl={logoSrc || ''}
        className={styles.buttons}
      />

      <div className={styles.imageContainer}>
        <img className={styles.image} src={logoSrc} alt="" />
      </div>
    </IntersectionAnimation>
  );
};
