// extracted by mini-css-extract-plugin
export var background = "styles-module--background--31b11";
export var blur = "styles-module--blur--f585d";
export var body = "styles-module--body--ede7f";
export var contact = "styles-module--contact--d0052";
export var contactBody = "styles-module--contactBody--aadd2";
export var contactBodyText = "styles-module--contactBodyText--b1e63";
export var container = "styles-module--container--b5824";
export var content = "styles-module--content--24df1";
export var desktop = "1340px";
export var ellipsis = "styles-module--ellipsis--f1867";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var layout = "styles-module--layout--1ebc1";
export var link = "styles-module--link--f8b81";
export var mobile = "400px";
export var mobileWidget = "styles-module--mobileWidget--1516f";
export var scrollPadding = "styles-module--scrollPadding--17a03";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var stamp = "styles-module--stamp--5bdbd";
export var stickerAnimation = "styles-module--stickerAnimation--8f2a0";
export var stickerContainer = "styles-module--stickerContainer--b88dd";
export var stickerImage = "styles-module--stickerImage--ffd72";
export var stickersContainer = "styles-module--stickersContainer--b8842";
export var stickersRelativeDesktop = "styles-module--stickersRelativeDesktop--47fc9";
export var stickersRelativeMobile = "styles-module--stickersRelativeMobile--2566c";
export var tablet = "769px";
export var text = "styles-module--text--dcd24";
export var textWrapper = "styles-module--textWrapper--a5496";
export var widgetContainer = "styles-module--widgetContainer--4cdbe";