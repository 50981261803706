import React from 'react';
import {
  LayoutProvider,
  SliceHeader,
} from '@lam-agency/toolkit/components';
import { ISliceTextMediaColumns } from 'lam-toolkit-figma-plugin';
import { TextMediaColumns } from './components/TextMediaColumns';

export const FigmaTextMediaColumns = ({
  textMediaColumns,
  sliceHeader
}: ISliceTextMediaColumns) => {
  return (
    <LayoutProvider paddingX paddingY>
      <SliceHeader {...sliceHeader} />

      {textMediaColumns && <TextMediaColumns {...textMediaColumns} />}
    </LayoutProvider>
  );
};
