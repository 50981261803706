import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import * as styles from './styles.module.scss';
import { LayoutProvider, TextAnimation } from '@lam-agency/toolkit/components';
import {
  useBreakpoints,
  useScroll,
  useWindowDimensions
} from '@lam-agency/toolkit/hooks';
import Draggable from 'react-draggable';
import { ATFWidget } from './components/ATFWidget';

const FigmaWineATF = () => {
  const [contentHeight, setContentHeight] = useState(9999); // Prevent scroll animations from triggering on slices below before content is loaded

  const contentRef = useRef<HTMLDivElement>(null);
  const { scrollY } = useScroll();
  const { height } = useWindowDimensions();
  const { smallDesktop } = useBreakpoints();

  const contentOverflowHeight = Math.max(0, contentHeight - height);
  const contentTransformY = smallDesktop
    ? -Math.min(contentOverflowHeight, scrollY)
    : 0;

  // const imageItems = useFigmaImage(images);

  const amountToScrollBeforeBlurPx = 300; // sync with amoutToScrollBeforeFill in Header.tsx
  const blurMultiplier = 0.02;
  const normalisedScroll = Math.max(0, scrollY - amountToScrollBeforeBlurPx);
  const blurAmountPx = normalisedScroll * blurMultiplier;

  const darkenMultiplier = 0.002;
  const maxDarkenOpacity = 0.75;
  const darkenOpacity = Math.min(
    maxDarkenOpacity,
    normalisedScroll * darkenMultiplier
  );

  const imageScaleMultiplier = 0.0002;
  const maxScale = 1.1;
  const minScale = 1;

  const imageScale = Math.max(
    minScale,
    maxScale - normalisedScroll * imageScaleMultiplier
  );

  useEffect(() => {
    const calculateContentHeight = () => {
      if (!contentRef.current) return;

      const containerHeight = contentRef.current.clientHeight;
      setContentHeight(containerHeight);
    };

    setTimeout(calculateContentHeight, 100);

    window.addEventListener('resize', calculateContentHeight);

    return () => {
      window.removeEventListener('resize', calculateContentHeight);
    };
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.content}
          ref={contentRef}
          style={{ transform: `translateY(${contentTransformY}px)` }}
        >
          <img
            aria-hidden
            className={styles.background}
            src="/images/curtain.jpg"
            alt=""
            style={{ transform: `scale(${imageScale})` }}
          />

          <LayoutProvider grid paddingX paddingY className={styles.layout}>
            <div style={{ color: 'white' }} className={styles.textWrapper}>
              <TextAnimation
                className="d1"
                text="New World Wine Awards ToolKit™"
                animation="fade-grow"
                speed={0.03}
              />
            </div>

            <div className={styles.widgetContainer}>
              <ATFWidget className={styles.widget} />
            </div>
          </LayoutProvider>

          <Stickers scrollY={scrollY} />

          <div
            aria-hidden
            style={{
              backdropFilter: `blur(${blurAmountPx}px)`,
              backgroundColor: `rgba(0, 0, 0, ${darkenOpacity})`
            }}
            className={styles.blur}
          />
        </div>
      </div>
      <div
        aria-hidden
        style={{ height: contentHeight }}
        className={styles.scrollPadding}
      />
    </>
  );
};

export default FigmaWineATF;

const Sticker = ({
  src,
  left,
  rotation,
  top,
  index
}: {
  src: string;
  top: string;
  left: string;
  rotation: string;
  index: number;
}) => {
  const delayStaggerMS = 50;

  return (
    <Draggable>
      <div className={styles.stickerContainer} style={{ top, left }}>
        <div
          style={{ animationDelay: `${index * delayStaggerMS}ms` }}
          className={styles.stickerAnimation}
        >
          <img
            draggable={false}
            style={{ transform: `rotate(${rotation})` }}
            aria-hidden
            src={src}
            alt=""
            className={styles.stickerImage}
          />
        </div>
      </div>
    </Draggable>
  );
};

const Stickers = ({ scrollY }: { scrollY: number }) => {
  const scrollTransformMultiplier = 0.07;
  const translateY = -scrollY * scrollTransformMultiplier;

  const containerStyles: CSSProperties = {
    transform: `translateY(${translateY}px)`
  };

  return (
    <div style={containerStyles} className={styles.stickersContainer}>
      <div className={styles.stickersRelativeDesktop}>
        <Sticker
          src="/images/medal-bronze.png"
          left="70%"
          rotation="30deg"
          top="5%"
          index={1}
        />
        <Sticker
          src="/images/medal-silver.png"
          left="68%"
          rotation="-10deg"
          top="45%"
          index={2}
        />
        <Sticker
          src="/images/medal-gold.png"
          left="80%"
          rotation="-20deg"
          top="17%"
          index={3}
        />
      </div>

      <div className={styles.stickersRelativeMobile}>
        <Sticker
          src="/images/medal-bronze.png"
          left="70%"
          rotation="30deg"
          top="20%"
          index={1}
        />
        <Sticker
          src="/images/medal-silver.png"
          left="80%"
          rotation="-10deg"
          top="25%"
          index={2}
        />
        <Sticker
          src="/images/medal-gold.png"
          left="75%"
          rotation="-20deg"
          top="30%"
          index={3}
        />
      </div>
    </div>
  );
};
