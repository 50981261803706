import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { useAccordion } from '@lam-agency/toolkit/hooks';
import { SVG } from '@lam-agency/toolkit/components';

interface IProps {
  className: string;
}

const links: { text: string; url: string }[] = [
  {
    text: 'Logo Kit',
    url: 'https://www.dropbox.com/scl/fo/mj84a1xy43s3sufe58dn0/APTbIQ6tbgJdV8DnW5P2lYU?rlkey=v2w7x27m4y6ejh99bryfsba77&dl=1'
  },
  {
    text: 'Font Files',
    url: 'https://www.dropbox.com/scl/fo/rwncc828hpef8684l83m0/AEPMHR5dzf8gtgpxoOZt8sg?rlkey=z9m2xkel7ffzyxaebauupu5hc&dl=1'
  },
  {
    text: 'Colour Swatches',
    url: 'https://www.dropbox.com/scl/fo/ktjalafjygs2a5ak9wui0/AEeqRjsSRWbQPPWeMpSfwcI?rlkey=zzprxk11o51kyvnrwmmgy3w48&dl=1'
  },
  {
    text: 'Imagery',
    url: 'https://www.dropbox.com/scl/fo/15wl3qn5u9z9rk4irjutz/AB2td4CXzavycV1e8GJNumw?rlkey=zimk6ri9pa2kuq7m4ju469vks&dl=1'
  },
  {
    text: 'Medals Kit',
    url: 'https://www.dropbox.com/scl/fo/o887p53oy8jy2oj2f1yb1/APVTiQ4d7VRri8RVfeEusao?rlkey=pwe45kedq651t0ghlea1warkd&dl=1'
  },
  {
    text: 'PR Guides',
    url: 'https://www.dropbox.com/scl/fo/be2ahhgecw1cb5c6mg94j/AEbTUMx2l_LD2MNHi6EReQ8?rlkey=xyi96hpk5r85z3j0ywae1upx5&dl=1'
  }
];

export const ATFWidget = ({ className }: IProps) => {
  const { containerHeight, contentRef, isExpanded, setIsExpanded } =
    useAccordion();

  return (
    <div className={cn(styles.container, className)}>
      <button
        onClick={() => setIsExpanded((prev) => !prev)}
        className={styles.button}
      >
        <span className="b2">Downloadable Assets</span>
        <SVG
          className={cn(styles.icon, { [styles.rotate]: isExpanded })}
          svg="chevronDown"
        />
      </button>
      <div
        style={{ height: containerHeight }}
        className={styles.contentContainer}
      >
        <div ref={contentRef}>
          {links.map((link, i) => (
            <a
              className={cn(styles.link, 'b1')}
              key={i}
              href={link.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <span>{link.text}</span>

              <span className={cn('button', styles.downloadLabel)}>
                Download
                <SVG svg="download" className={styles.icon} />
              </span>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
