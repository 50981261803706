import React from 'react';
import cn from 'classnames';
import {
  LayoutProvider,
  MediaItem,
  SliceHeader,
  TwoColumnText
} from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { ISliceMediaGallery } from 'lam-toolkit-figma-plugin';

export const FigmaMediaGallery = ({
  gallerySections,
  sliceHeader,
  twoColumnText
}: ISliceMediaGallery) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <LayoutProvider paddingX paddingY>
          {sliceHeader && <SliceHeader {...sliceHeader} />}

          {twoColumnText && <TwoColumnText {...twoColumnText} />}

          {gallerySections?.map((sectionItems, i) => (
            <LayoutProvider grid key={i}>
              {sectionItems.map((mediaItem, j) => (
                <MediaItem
                noBorder  
                aspectRatio={
                    sectionItems.length > 1 ? 'square' : 'landscapeShorter'
                  }
                  className={cn(styles.gridItem, {
                    [styles.oneItem]: sectionItems.length === 1,
                    [styles.twoItems]: sectionItems.length === 2,
                    [styles.threeItems]: sectionItems.length === 3
                  })}
                  key={j}
                  {...mediaItem}
                />
              ))}
            </LayoutProvider>
          ))}
        </LayoutProvider>
      </div>
    </div>
  );
};
